
































































































































































































import Vue from 'vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { Camera, Group } from './types';

const Ripple = require('vue-ripple-directive');

interface EditCamera extends Camera {
  dirty: boolean | undefined;
  locationStr: string;
}

export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    const columns = [
      {
        key: 'operation',
        label: '',
      },
      {
        key: 'general',
        label: '一般',
      },
      {
        key: 'topic',
        label: '辨識設定',
      },
      {
        key: 'eventTrigger',
        label: '事件觸發設定',
      },
      {
        key: 'roiConfig',
        label: '辨識區間',
      },
    ];
    const topics = [
      { text: '車辨及車斗覆蓋', value: '車辨及車斗覆蓋' },
      { text: '露天燃燒', value: '露天燃燒' },
      { text: '河川揚塵', value: '河川揚塵' },
      { text: '煙囪排放', value: '煙囪排放' },
      { text: '工地揚塵', value: '工地揚塵' },
      { text: '工廠逸散', value: '工廠逸散' },
    ];

    const llmOptions = [
      { text: 'ChatGPT 4-o', value: 'gpt-4o' },
      { text: 'ChatGPT 4-o-mini', value: 'gpt-4o-mini' },
    ];

    let cameraList = Array<EditCamera>();
    let groupList = Array<Group>();
    let form: Camera = {
      _id: '',
      name: '',
      folder: '',
      question: '',
      group: undefined,
    };
    return {
      llmOptions,
      topics,
      cameraList,
      columns,
      groupList,
      form,
    };
  },
  computed: {
    canAddCamera(): boolean {
      return !!this.form.name;
    },
  },
  async mounted() {
    await this.getGroupList();
    await this.getCameraList();
  },
  methods: {
    async getCameraList() {
      const res = await axios.get('/Cameras');
      if (res.status === 200) {
        this.cameraList = res.data;
        for (const camera of this.cameraList) {
          if (camera.location && camera.location.length == 2)
            camera.locationStr = `${camera.location[0]},${camera.location[1]}`;
        }
      }
    },
    async getGroupList() {
      const res = await axios.get('/Groups');
      if (res.status === 200) {
        this.groupList = res.data;
      }
    },
    showAddCameraModal() {
      this.$bvModal.show('addCameraModal');
    },
    async addCamera() {
      const camera: Camera = this.form;
      const res = await axios.post(`/Camera`, camera);
      if (res.status == 200) this.$bvModal.msgBoxOk('成功');
      await this.getCameraList();
    },
    async deleteCamera(row: any) {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `確定要刪除${row.item.name}?`,
        { okTitle: '確認', cancelTitle: '取消' },
      );

      if (!confirm) return;

      const res = await axios.delete(`/Camera/${row.item._id}`);
      if (res.status == 200) this.$bvModal.msgBoxOk('成功');
      await this.getCameraList();
    },
    sanityCheck(camera: EditCamera) {
      if (!camera.roiConfigX) camera.roiConfigX = undefined;
      if (!camera.roiConfigY) camera.roiConfigY = undefined;
      if (!camera.roiConfigWidth) camera.roiConfigWidth = undefined;
      if (!camera.roiConfigHeight) camera.roiConfigHeight = undefined;
      if (camera.locationStr) {
        const loc = camera.locationStr.split(',');
        if (loc.length == 2) {
          camera.location = [parseFloat(loc[0]), parseFloat(loc[1])];
        } else camera.location = undefined;
      } else camera.location = undefined;

      return camera.dirty;
    },
    save() {
      const all = Array<any>();
      for (const camera of this.cameraList) {
        if (camera.dirty) {
          this.sanityCheck(camera);
          all.push(axios.post(`/Camera`, camera));
        }
      }
      Promise.all(all).then(() => {
        this.$bvModal.msgBoxOk('成功');
        this.getCameraList();
      });
    },
    rollback() {
      this.$bvModal.msgBoxOk('成功');
    },
    markDirty(item: EditCamera) {
      item.dirty = true;
    },
  },
});
